import {AbOrm} from "ab-orm/src/AbOrm.js";

/**
 * User model
 */
export class Translation extends AbOrm {
    static entity = 'translation'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        application_id: "int",
        max_length: "int",
        language: "string",
        variable: "string",
        value: "string",
        file_type: "string",
        translatable: "int",
    }


    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        return this.app().auth().hasRole("user") ? row : false
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'translation': {
                subscribe: (app_id) => app_id,
                init: async (app_id) => this.getList(app_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(app_id) {
        return this.query().where({application_id: app_id}).get()
    }
}
