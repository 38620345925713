import {Application} from "@/../../db/models/Application"
import {User} from "@/../../db/models/User"
import {Translation} from "@/../../db/models/Translation"

export default {

    // Save db
    save: async (data) => {
        console.log("Save", data.length)
    },

    // Load db
    load: async () => {
        console.log("Load data...")
        return false
    },


    webWasmFile: () => "/js/sql-wasm.wasm",

    models: [
        Application,
        User,
        Translation,
    ]
}
