<template>
  <q-page class="q-pa-md">
    <q-dialog ref="editUser">
      <user-form class="full-width" :title-key="userFormTitlekey" :edit-user="editUser" @saved="onUserSaved"/>
    </q-dialog>

    <div class="row">
      <q-table
        class="full-width"
        dense
        :title="$t('application.users')"
        :rows="users"
        :columns="columns"
        :no-data-label="$t('user.noUsers')"
        row-key="name"
      >
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn dense round flat color="grey" icon="edit_square" @click="onEditUser(props)"></q-btn>
            <q-btn dense round flat color="grey" icon="delete" @click="onDeleteUser(props)"></q-btn>
          </q-td>  
        </template>
      </q-table>
    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-btn fab icon="add" color="primary" @click="onFabBtnCick"/>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>

import UserForm from "./UserForm";
import {User} from "@/../../db/models/User";

export default {
  name: 'UsersList',
  components: { UserForm },
  data: vm => ({
    userFormTitlekey: 'user.edit',
    editUser: false,
    columns: [
      { name: 'first_name', label: vm.$t('user.firstName'), field: 'first_name', sortable: true },
      { name: 'last_name', label: vm.$t('user.lastName'), field: 'last_name', sortable: true },
      { name: 'email', label: vm.$t('user.email'), field: 'email', sortable: true },
      { name: 'roles', label: vm.$t('user.roles'), field: 'roles', align: 'center', format: (val) => val?.join(', ') },
      { name: 'actions', label: vm.$t('actions.actions'), field: 'actions', align: 'center', },
    ]
  }),

  /**
   * On page created
  */
  async created() {
    // Fetch all users
    await User.remote().subscribe('users')
  },

  computed: {
    // Return all Users from the store
    users() {
      return this.wait('users', User.query().get(), [])
    }
  },

  methods: {
    onFabBtnCick() {
      this.userFormTitlekey = 'user.add';
      this.editUser = {};
      this.$refs.editUser.show();
    },

    onUserSaved() {
      this.$refs.editUser.hide();
    },

    onEditUser(prop) {
      this.userFormTitlekey = 'user.edit';
      this.editUser = prop.row.data();
      this.$refs.editUser.show();
    },

    /**
     * Delete Users
     * @param Users
     * @return {Promise<void>}
     */
    async onDeleteUser(prop) {
      const userData = prop.row.data();
      this.$q.dialog({
        title: this.$t('dialog.delete'),
        message: this.$t('dialog.deleteConfirmation', { item: `user: ${userData.email}`}),
        cancel: true,
        persistent: true
      }).onOk(() => {
        User.remote().delete(userData.id)
      })
    },
  }
}
</script>
