<template>

  <q-card>
    <q-card-section>
      <div class="text-h6">{{$t(titleKey)}}</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <application-fields :editApplication="editApplication" @saved="onSaved" />

    </q-card-section>

  </q-card>

</template>

<script>
import ApplicationFields from './ApplicationFields.vue'

export default {
  name: "ApplicationForm",
  components: {ApplicationFields},
  props: {
    titleKey: {
      type: String,
      default: 'user.edit'
    },
    editApplication: {
      type: Object,
      required: true
    }
  },
  emits: ['saved'],
  methods: {
    onSaved() {
      // Hide dialog
      this.$emit('saved');
    }
  }
}

</script>
