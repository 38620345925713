import {Quasar} from "quasar"
import { createApp } from 'vue'
import App from '@/App.vue'
import AbApp from "ab-application/src/install"
import {routes} from "./routes";
import translations from "../../locales/translations";
import db from "./db";
import stateConfig from "@/config";
import {constants} from "../../constants/constants"
import "./fonts"
import quasarUserOptions from "@/quasar-user-options";

// Create app
const app = createApp(App);

// Install quasar
app.use(Quasar, quasarUserOptions);

// Config.
app.config.unwrapInjectedRef = true

// Init app
app.use(AbApp, {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    authURL: process.env.VUE_APP_BACKEND_URL,
    socketURL: process.env.VUE_APP_SOCKET_URL,
    routes,
    translations,
    config: stateConfig,
    db
    },
    constants,() => {
    app.mount('#app');
})
