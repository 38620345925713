/**
 * Application model
 */
import {AbOrm} from "ab-orm/src/AbOrm.js";

export class Application extends AbOrm {
    static entity = 'applications'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        name: "string",
        description: "string",
        secret_key: "string",
        bundle_id: "string",
        languages: "json",
        default_language: "string",
        users: "json",
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        return this.app().auth().hasRole("user") ? row : false
    }


    /**
     * Partitions list
     */
    async channels() {
        return {
            'applications': {
                subscribe: () => 1,
                init: async () => this.getList(),
            }
        }
    }

    /**
     * Get list
     */
    getList() {
        return this.query().get()
    }
}
