<template>
  <q-layout view="lHh Lpr lFf" v-if="isReady">
    <q-header >
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title>
          {{$t("common.application.name")}}
        </q-toolbar-title>

        <component v-if="$route.meta.toolbar" :is="$route.meta.toolbar"/>

      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      elevated
      class="bg-grey-2"
    >
      <workspace-navigation/>
    </q-drawer>

    <q-page-container>
      <router-view :key="$route.fullPath"/>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import WorkspaceNavigation from "@/pages/workspace/components/WorkspaceNavigation";
import {Application} from "@/../../db/models/Application";

export default {
  name: 'WorkspaceTemplate',
  data: () => ({
    isReady: false
  }),

  /**
   * On page created
   */
  async created() {

    // Fetch all applications and ideas
    await Application.remote().subscribe('applications')

    // Loaded
    this.isReady = true

  },

  components: {
    WorkspaceNavigation
  },

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
