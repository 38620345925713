<template>
  <q-card>
    <q-card-section>
      <div class="text-h6">Add variable</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="form" class="q-gutter-md" @submit="onSaveApplication">
        <q-input
          filled
          v-model="mutate(data).variable"
          label="Variable  *"
          lazy-rules
          :rules="[ val => val && val.length > 0 || $t('validation.required')]"
        />
        <q-input
          filled
          v-model="mutate(data).string"
          label="String  *"
          lazy-rules
          :rules="[ val => val && val.length > 0 || $t('validation.required')]"
        />
        <q-input
          filled
          type="number"
          v-model="mutate(data).maxLength"
          label="Max length *"
          lazy-rules
          :rules="[ val => val && val.length > 0 || $t('validation.required')]"
        >
          <template v-slot:hint>
            String length: {{data.string.length}}
          </template>
        </q-input>
        <q-checkbox v-model="mutate(data).noTranslate" label="Don't translate" />
      </q-form>
    </q-card-section>

    <q-card-actions class="q-px-md q-py-md justify-end">
      <q-btn class="q-mx-md" label="Save" color="primary" @click="onSave"/>
      <q-btn class="q-mx-md" label="Cancel" color="secondary" @click="onCancelClick" />
    </q-card-actions>

  </q-card>

</template>

<script>
export default {
  name: "ApplicationTranslationAddVariable",
  emits: ['close'],
  props: {
    defaultData: {
      type: Object,
      default: ()=> ({
        variable: "",
        string: "",
        noTranslate: false,
        maxLength: null,
      })
    }
  },
  data: () => ({
    data: {},
  }),
  created() {
    this.data = this.defaultData
  },
  methods: {
    onSave() {
      this.$refs.form.submit();
    },
    onSaveApplication() {
      this.onClose(this.data);
    },
    onCancelClick() {
      this.onClose();
    },
    onClose(data) {
      this.$emit('close', data || null);
      this.data = this.defaultData;
    }
  }
}

</script>
