<template>

  <div>
    <q-dialog ref="uploadDialog">
      <application-translation-upload
          class="full-width"
          :application="application"
          :language-options="languageOptions"
          @close="onUploaded"
      />
    </q-dialog>

    <q-dialog ref="addVariable">
      <application-translation-add-variable
          class="full-width"
          :application="application"
          :language-options="languageOptions"
          @close="onAddVariableDialogClose"
      />
    </q-dialog>

    <div class="row justify-between">
      <div class="text-h5">{{ application?.name }} - {{ $t('application.translations')}}</div>
      <div class="row justify-between">
        <div>
          <q-checkbox class="q-mx-md" v-model="translateAll" label="Translate all" />
          <q-btn
              :disable="(selectedLanguageFrom === selectedLanguageTo || getRows.length === 0) && !translateAll"
              label="Auto translate"
              @click="autoTranslate"
          />
        </div>
        <q-btn
            :disable="isLoading"
            class="q-mx-md"
            icon="download"
            label="XML"
            @click="onDownload('text/xml')"
        />
        <q-btn
            :disable="isLoading"
            icon="download"
            label="JSON"
            @click="onDownload('application/json')"
        />
        <q-btn
            class="q-ml-md"
            :disable="isLoading"
            icon="download"
            label="STRINGS"
            @click="onDownload('strings')"
        />
      </div>
    </div>

    <div class="table-wrapper row q-mt-md">
      <q-table
          class="table full-width"
          dense
          color="primary"
          :loading="isLoading || isAutoTranslation"
          :rows="filteredRows"
          :no-data-label="$t('common.noResults')"
          row-key="variable"
          :filter="search"
          :rows-per-page-options="[20]"
      >
        <template v-slot:top-right>
          <q-input class="q-mx-md" clearable borderless dense debounce="300" v-model="search" placeholder="Search">
            <template v-slot:prepend>
              <q-icon name="search" />
            </template>
          </q-input>
          <q-checkbox class="q-mx-md" v-model="filterForTo" label="Without translation" />
          <q-checkbox class="q-mx-md" v-model="filterForLength" label="Exceed max length" />
          <q-btn color="primary" label="Add variable" @click="onAddStringClick" />
        </template>
        <template v-slot:no-data="{ message }">
          <div class="full-width row flex-center q-gutter-sm">
            <p v-if="isLoading">Loading...</p>
            <div v-else>{{ message }}</div>
          </div>
        </template>
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th>
              {{$t('translations.variable')}}
            </q-th>
            <q-th>
              <q-select
                  emit-value
                  :label="$t('translations.from')"
                  v-model="selectedLanguageFrom"
                  :options="languageOptions"
              />
            </q-th>
            <q-th>
              <div class="row no-wrap">
                <q-select
                    class="full-width"
                    emit-value
                    :label="$t('translations.to')"
                    v-model="selectedLanguageTo"
                    :options="languageOptions"
                />
              </div>
            </q-th>
            <q-th>
              Max length
              <q-btn v-if="application.default_language !== selectedLanguageTo" color="grey" @click="fixMaxLengthHandler">Fix</q-btn>
            </q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="variable" :props="props">
              <div class="row items-center no-wrap justify-between">
                <div>{{ props.row.variable }}</div>
                <q-btn
                    class="q-ml-md"
                    size="sm"
                    round
                    color="grey"
                    icon="delete"
                    @click="onDeleteRow(props.row)"
                />
              </div>
            </q-td>
            <q-td key="from" :props="props">
              {{ props.row.from }}
            </q-td>

            <q-td key="to" :props="props">
              <div :class="{'text-negative': props.row.to?.length > props.row.max_length  ? true : false }">{{ props.row.to }}</div>
              <q-popup-edit v-model="props.row.to" v-slot="scope">
                <q-input autogrow v-model="scope.value" dense autofocus counter />
                <q-btn
                    color="primary"
                    label="Save"
                    @click="()=>{ onLanguageToEdit(props.row, scope.value); scope.set();}"
                />
              </q-popup-edit>
            </q-td>
            <q-td key="max_length" :props="props">
              <div>{{ props.row.max_length }}</div>
              <q-popup-edit v-model="props.row.max_length" v-slot="scope">
                <q-input v-model="scope.value" dense autofocus counter />
                <q-btn
                    color="primary"
                    label="Edit"
                    @click="()=>{ onLengthEdit(props.row, scope.value, scope); scope.set();}"
                />
              </q-popup-edit>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab color="primary" icon="keyboard_arrow_left" direction="left">
        <q-fab-action color="primary" label="Upload" icon="upload" @click="onUpload" />
        <q-fab-action color="secondary" label="Download all json (zip)" icon="download" @click="onDownloadArchive('json')" />
        <q-fab-action color="secondary" label="Download all xml (zip)" icon="download" @click="onDownloadArchive('xml')" />
        <q-fab-action color="secondary" label="Download all strings (zip)" icon="download" @click="onDownloadArchive('strings')" />
      </q-fab>
    </q-page-sticky>
  </div>

</template>

<script>
import {Translation} from "@/../../db/models/Translation";
import ApplicationTranslationUpload from "./ApplicationTranslationUpload.vue";
import ApplicationTranslationAddVariable from "./ApplicationTranslationAddVariable.vue";

import {Translator} from '@/utils/Translator.js'

export default {
  name: "ApplicationTranslations",
  components: {ApplicationTranslationUpload, ApplicationTranslationAddVariable},
  props: ['application'],
  data: () => ({
    search: '',
    translateAll: false,
    filterForTo: false,
    filterForLength: false,
    isLoading: true,
    translationTo: [],
    selectedLanguageFrom: "",
    selectedLanguageTo: "",
    isAutoTranslation: false,
  }),
  /**
   * On page created
   */
  async created() {
    // Fetch all translation
    try{
      await Translation.remote().subscribe('translation', this.application.id);
      this.isLoading = false;
    }
    catch(error) {
      this.isLoading = false;
      this.triggerNotify(error.message || error, 'negative');
    }

    this.selectedLanguageFrom = this.application?.default_language;
    this.selectedLanguageTo = this.application?.default_language;
  },
  computed: {
    getFromStrings() {
      return this.wait('translation', Translation.query().where({application_id: this.application.id}).where({language: this.selectedLanguageFrom}).get(), [])
    },
    filteredRows() {
      let result = this.getRows;

      if(this.filterForTo) {
        result = result.filter(row => !row.to || row.to === '');
      }

      if(this.filterForLength) {
        result = result.filter(row => row.to?.length > row.max_length);
      }

      return result;
    },
    getRows() {
      const unq = {};
      this.getFromStrings.map((item) => {
        unq[item.variable] = {
          variable: item.variable,
          from: item.value,
          to: this.translationTo.length > 0 ?
              this.translationTo.find(translateTo => translateTo.variable === item.variable)?.value
              : "",
          max_length: item.max_length,
          translatable: item.translatable,
        }
      })

      // Return unique values
      return Object.values(unq)
    },
    languageOptions() {
      return this.globals.options.languages.filter((lang) => this.application.languages?.includes(lang.value))
    }
  },
  watch: {
    selectedLanguageTo: {
      async handler(languageTo) {
        this.translationTo = (await Translation.query().where({application_id: this.application.id, language: languageTo}).get());
      },
      deep: true
    }
  },
  methods: {
    async fixMaxLengthHandler() {
      const defaultLangData = await Translation.query()
          .where({application_id: this.application.id, language: this.application.default_language }).get();

      const curentLangData = await Translation.query()
          .where({ application_id: this.application.id, language: this.selectedLanguageTo}).get();

      const promises = curentLangData.map(async (data) => {
        const defaultObj = defaultLangData.find(defaultData => defaultData.variable === data.variable)

        if(defaultObj.max_length === data.max_length) return;

        await Translation.remote().save({id: data.id, max_length: defaultObj.max_length })
      });

      await Promise.all(promises);
      this.triggerNotify("Max Length Fixed");
    },
    onAddStringClick() {
      this.$refs.addVariable.show();
    },
    async onAddVariableDialogClose(data) {
      try {
        if(data) {
          const promises = this.application.languages.map(async (language) => {
            await Translation.remote().save({
              application_id: this.application.id,
              language: language,
              max_length: Number(data.maxLength),
              variable: data.variable,
              value: language === this.application.default_language ? data.string : "",
              translatable: Number(!(data.noTranslate)),
            })
          });

          await Promise.all(promises);
          this.triggerNotify(`Variable ${data.variable} added`);
        }
        this.$refs.addVariable.hide();
      } catch(error) {
        this.triggerNotify(error.message || error, 'negative');
      }

    },
    async autoTranslate() {
      this.$q.loading.show({
        message: 'Auto translating...'
      });
      this.isAutoTranslation = true;

      try {

        for(const translateObj of this.getRows) {
          if(translateObj.to) continue;
          if(!this.translateAll) {
            await this.translateText(translateObj, this.selectedLanguageTo);
          } else {
            await this.translateAllText(translateObj);
          }
        }

        /*const promises = this.getRows.filter(translateObj => !translateObj.to)
            .map(translateObj => {
              if(!this.translateAll) {
                return this.translateText(translateObj, this.selectedLanguageTo);
              }
              return this.translateAllText(translateObj);
            });

        await Promise.all(promises);*/

        if(this.translateAll) {
          this.selectedLanguageTo = this.application.languages[this.application.languages.length - 1];
        }

        this.triggerNotify('Auto translation completed');
      } catch (error) {
        this.isAutoTranslation = false;
        this.$q.loading.hide();
        console.error(error);
        this.triggerNotify(error.message || error, 'negative');
      }

      this.isAutoTranslation = false;
      this.$q.loading.hide();
    },
    async translateText(translateObj, languageTo) {
      const textForTranslate = translateObj.from && typeof(translateObj.from) === 'string' && translateObj.from.length > 0 ?
          translateObj.from
              .replace(/\{(\w+)\}/g, '<code class="brace">$1</code>')
              .replace(/%(\d+\$)?([sd])/g, match => '<code>' + match + '</code>')
              .replace(/(^|\s)@(\S+)/g, (_, p1, p2) => `${p1}<code>@${p2}</code>`)
              .replace(/\\'/g, "'")
              .replace(/\\n/g, '<code class="n"></code>')
              .replace(/<br>/g, '<code class="br"></code>')
              .replace(/ +/g, ' ')
              .replace(/“/g, '&ldquo;')
              .replace(/”/g, '&rdquo;')
              .trim()
          : translateObj.from;
      let translatedText = "";

      if((typeof(textForTranslate) !== 'string') || !translateObj.translatable) {
        translatedText = textForTranslate;
      } else if(textForTranslate !== "") {
        translatedText = await Translator.translate(this.selectedLanguageFrom, languageTo, textForTranslate);
      }

      if(translatedText === false) {
        throw new Error('Error translating');
      }

      let replacedText = ((typeof(translatedText) === 'string') && translateObj.from.length > 0) || !translateObj.translatable ?
          translatedText
              .replace(/<code class="brace">(\w+)<\/code>/g, '{$1}')
              .replace(/<code class="n"><\/code>/g, '\\n')
              .replace(/<code class="br"><\/code>/g, '<br>')
              .replace(/<code>(.+?)<\/code>/g, '$1')
              .replace(/'/g, "\\'")
              .replace(/&ldquo;/g, '“')
              .replace(/&rdquo;/g, '”')
              .replace(/&quot;/g, '"')
          : translatedText;

      if (typeof(textForTranslate) === 'string') {
        if(replacedText.includes('.') && textForTranslate[textForTranslate.length-1] !== '.' && replacedText[replacedText.length-1] === ".") {
          replacedText = replacedText.replace(/\.$/, "");
        }
        if (textForTranslate.match(/[^ ]:/)) {
          if (/\s:/.test(replacedText)) {
            replacedText = replacedText.replace(/\s:/, ":");
          }
        }
        if (textForTranslate.match(/[^ ]\./)) {
          if (/\s\./.test(replacedText)) {
            replacedText = replacedText.replace(/\s\./, ".");
          }
        }
      }

      const newObj = {
        application_id: this.application.id,
        language: languageTo,
        variable: translateObj.variable,
        max_length: translateObj.max_length,
        translatable: translateObj.translatable,
        value: replacedText,
      }

      const data = await Translation.query()
          .where({application_id: this.application.id, language: languageTo, variable: translateObj.variable }).first();

      if(data?.id) {
        await Translation.remote().save({id: data.id, value: newObj.value});
      } else {
        await Translation.remote().save(newObj);
      }
      this.updateTranslationTo(translateObj.variable, newObj)
    },
    async translateAllText(translateObj) {
      const languagesForTranslateArr = this.application.languages.filter(language => language !== this.selectedLanguageFrom);
      for (const language of languagesForTranslateArr) {
        await this.translateText(translateObj, language);
      }
    },
    triggerNotify(message, type="positive") {
      this.$q.notify({
        type,
        message,
      })
    },
    setDefaultTranslationTo() {
      if(this.translationTo.length === 0) {
        this.translationTo = this.getFromStrings.map(item => ({
          variable: item.variable,
          value: "",
        }))
      }
    },
    async onDeleteRow(row) {
      this.$q.dialog({
        title: this.$t('dialog.delete'),
        message: this.$t('dialog.deleteConfirmation', { item: `variable: ${row.variable}`}),
        cancel: true,
        persistent: true
      }).onOk(async () => {
        try {
          const data = await Translation.query()
              .where({application_id: this.application.id, variable: row.variable }).get();

          for (const translation of data) {
            await Translation.remote().delete(translation.id)
          }
        } catch (error) {
          this.triggerNotify(error.message || error, 'negative');
          console.error(error.message || error);
        }
      })
    },
    async onLanguageToEdit(row, value) {
      this.setDefaultTranslationTo();

      try {
        const defaultData = await Translation.query()
            .where({ application_id: this.application.id, language: this.application.default_language, variable: row.variable})
            .first();

        const newObj = {
          application_id: this.application.id,
          language: this.selectedLanguageTo,
          variable: row.variable,
          value: value.toString(),
          max_length: defaultData.max_length
        }

        const data = await Translation.query()
            .where({ application_id: this.application.id, language: this.selectedLanguageTo, variable: row.variable})
            .first();

        if(data?.id) {
          await Translation.remote().save({id: data.id, value: value.toString()});
        } else {
          await Translation.remote().save(newObj)
        }
        this.updateTranslationTo(row.variable, newObj)

      } catch (error) {
        this.triggerNotify(error.message || error, 'negative');
      }
    },
    async onLengthEdit(row, value) {
      try {
        this.$q.loading.show({
          message: 'Editing...'
        })
        const data = await Translation.query()
            .where({ application_id: this.application.id, language: this.selectedLanguageTo, variable: row.variable})
            .first();

        let defaultLangData = null;

        if(this.selectedLanguageTo !== this.application.default_language) {
          defaultLangData = await Translation.query()
              .where({ application_id: this.application.id, language: this.application.default_language, variable: row.variable})
              .first();
        } else {
          defaultLangData = data;
        }

        const newMaxLength = (!defaultLangData?.value?.length || value > defaultLangData?.value?.length) ? value : defaultLangData?.value?.length;

        await Translation.remote().save({id: data.id, max_length: newMaxLength});

        const otherLanguages = this.application.languages.filter(language => language !== this.selectedLanguageTo );

        const promises = otherLanguages.map(async (language) => {
          const otherData = await Translation.query()
              .where({application_id: this.application.id, language, variable: row.variable }).first();

          if(!otherData?.id) return;

          await Translation.remote().save({id: otherData.id, max_length: newMaxLength })
        });

        await Promise.all(promises);

        this.$q.loading.hide();
      } catch (error) {
        this.$q.loading.hide();
        this.triggerNotify(error.message || error, 'negative');
      }
    },
    updateTranslationTo(variable, newObj) {
      const item = this.translationTo.find((item => {
        return item.variable === variable
      }));

      if(item?.id) {
        item.value = newObj.value;
      } else {
        this.translationTo.push(newObj)
      }
    },
    onUpload() {
      this.$refs.uploadDialog.show();
    },
    async onUploaded(payload) {
      if(payload?.language) {
        this.selectedLanguageFrom = this.application?.default_language;
        this.selectedLanguageTo = payload.language;
        this.translationTo = (await Translation.query().where({application_id: this.application.id, language: payload.language}).get());
      }

      this.$refs.uploadDialog.hide();
    },
    async onDownload(type) {
      try {
        const [fileData, format, fileName] = await Translator.export(this.application.id, this.application.secret_key, type, this.selectedLanguageTo);
        this.downloadFile(fileData, format, fileName);
      }
      catch (error) {
        console.error(error.response?.data.exception || error.message || error)
        this.triggerNotify(error.response?.data.exception || error.message || error, 'negative');
      }
    },
    async onDownloadArchive(format) {
      try {
        const archive = await Translator.getArchive(this.application.id, format);

        this.downloadFile(archive, "application/zip", `${this.application.name}_${format}.zip`);
      }
      catch (error) {
        console.error(error.response?.data.exception || error.message || error)
        this.triggerNotify(error.response?.data.exception || error.message || error, 'negative');
      }
    },
    downloadFile(fileData, format, fileName) {
      const blob = new Blob([fileData], { type: format });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    }
  }
}

</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-bottom: 100px;

  .table {
    th, td {
      white-space: normal;
    }
    th:nth-of-type(1),
    td:nth-of-type(1) {
      width: 20%;
    }
    th:nth-of-type(2),
    td:nth-of-type(2) {
      width: 40%;
    }
    th:nth-of-type(3),
    td:nth-of-type(3) {
      width: 40%;
    }
  }
}

</style>
