import axios from "axios";

/**
 * Translator service
 */
export class Translator {

    /**
     * Translate string to language
     * @param text
     * @param language
     * @return {Promise<*>}
     */
    static async translate(from, to, text) {

        // Ask for translation
        const res = await axios.post(process.env.VUE_APP_TRANSLATOR, [from, to, text])

        // Error creating record
        if(res.status !== 200) {
            console.log("Error", res)
            throw "Error translating text"
        }

        // Newly created item
        return res.data
    }

    static async getArchive(id, format) {

        let res = false;

        try {
            // Ask for translation
            res = await axios.get(`${process.env.VUE_APP_EXPORT_TRANSLATED_ARCHIVE}/${id}/${format}`, {
                responseType: 'blob'
            })
        } catch (ex) {
            throw "Error: " + await ex.response?.data?.text()
        }

        // Error creating record
        if(res.status !== 200) {
            console.log("Error", res)
            throw "Error get archive"
        }

        // Newly created item
        return res.data
    }

    static async export(id, key, format, lang) {

        // Ask for translation
        const res = await axios.post(process.env.VUE_APP_EXPORT_TRANSLATED, [id, key, format, lang])

        // Error creating record
        if(res.status !== 200) {
            console.log("Error", res)
            throw "Error export file"
        }

        // Newly created item
        return res.data
    }


    /**
     * Get string length
     * @param str
     * @return {number}
     */
    static length = function (str) {
        {
            // No length
            if(!str) return 0

            var n = 0;
            var c, s = str.trim()
            for (var i = 0; i < s.length; i++) {
                c = s.charCodeAt(i);
                //Devanagari
                if(c >= 2304 && c <= 2431 ) {
                    //wrong calculate
                    n++;
                }
                //Thai
                else if(c >= 3584 && c <= 3711 ) {
                    n++;
                }
                else if (c > 65535) {
                    n+=3;
                }
                else if (c > 2047) {
                    n+=2;
                }
                else {
                    n++;
                }
            }
            return n;
        }
    }
}
