<template>

  <div>

    <div class="text-h5">{{$t('application.settings')}}</div>

    <application-fields :editApplication="application" />

  </div>

</template>

<script>
import ApplicationFields from './ApplicationFields.vue'

export default {
  name: "ApplicationSettings",
  components: {ApplicationFields},
  props: ['application'],
}

</script>
