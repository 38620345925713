<template>


  <q-toolbar class="bg-primary text-white text-subtitle1">

  </q-toolbar>

  <q-list>

    <q-tree ref="tree" @update:selected="onNodeSelected"
            :nodes="applicationsTree"
            v-model:expanded="expandedNodes"
            v-model:selected="selectedNode"
            node-key="key"
            no-selection-unset
    />

  </q-list>

</template>

<style>
</style>

<script>
import {Application} from "@/../../db/models/Application";

export default {
  name: 'WorkspaceNavigation',
  components: {},
  data: () => ({
    editApplication: false,
    expandedNodes: ['applications-list'],
    selectedNode: false
  }),

  computed: {
    // Return all applications from the store
    applications: () => Application.all(),

    /**
     * Construct navigation tree
     */
    applicationsTree() {

      // Construct navigation tree
      return this.wait("tree", async () => {

        // Prepare tree leafs
        const applicationsList = {
          label: "Applications list",
          key: "applications-list",
          expanded: true,
          children: []
        };

        // Process each application
        for (const p of await Application.query().get()) {

          // Add ex
          if (this.$route.params.application_id == p.id) this.expandedNodes.push(p.id)

          // Fill menu
          applicationsList.children.push({
            label: p.name,
            key: p.id,
          })
        }

        // Menu array
        const menu = []

        // Applications list
        if (this.app.auth().hasRole(['admin', 'user'])) menu.push(applicationsList)

        // Admin zone
        if (this.app.auth().hasRole(['admin'])) menu.push({
          label: "Admin zone",
          key: "admin",
          children: [
            {
              label: "Users",
              key: "admin/users",
            },
            // {
            //   label: "Applications report",
            //   key: "admin/admin-report-statistics",
            // }
          ]
        })

        // Return list
        return menu
      }, [])
    },
  },

  methods: {

    /**
     * On node selected
     * @param nodeId
     */
    onNodeSelected(nodeId) {
      if (nodeId) this.$router.push("/workspace/" + nodeId)
    },

    /**
     * Delete application
     * @param application
     * @return {Promise<void>}
     */
    async deleteApplication(application) {

      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${application.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        Application.remote().delete(application.id)
      })
    },
  }
}
</script>
