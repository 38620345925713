<template>
  <q-page class="q-pa-md">

    <q-dialog ref="editApplication">
      <application-form class="full-width" :title-key="applicationFormTitlekey" :edit-application="editApplication" @saved="$refs.editApplication.hide()"/>
    </q-dialog>

    <div class="row q-gutter-md">

      <template v-for="(application, k) of applications" :key="k">

        <q-card class="col-md-2 col-xs-12">

          <q-card-section class="bg-blue-1">
            <div class="text-subtitle1">{{ application.name }}</div>
          </q-card-section>

          <q-separator/>

          <q-card-actions align="right">
            <q-btn flat @click="applicationFormTitlekey = 'application.edit';editApplication = application.data();$refs.editApplication.show()">{{ $t('actions.edit') }}</q-btn>
            <q-btn flat @click="deleteApplication(application)">{{ $t('actions.delete') }}</q-btn>
          </q-card-actions>
        </q-card>

      </template>

    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-btn fab icon="add" color="primary" @click="applicationFormTitlekey = 'application.add';editApplication={};$refs.editApplication.show()"/>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>
import ApplicationForm from "./ApplicationForm";
import {Application} from "@/../../db/models/Application";

export default {
  name: 'ApplicationsList',
  components: { ApplicationForm},
  data: () => ({
    editApplication: false,
    applicationFormTitlekey: 'application.edit',
  }),

  computed: {
    // Return all applications from the store
    applications() {
      return this.wait('applications', Application.query().get(), [])
    }
  },

  methods: {

    /**
     * Delete application
     * @param application
     * @return {Promise<void>}
     */
    async deleteApplication(application) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${application.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        Application.remote().delete(application.id)
      })
    },
  }
}
</script>
