import {countriesList} from "./countries.js";
import {languagesList} from "./languages.js";
import {currenciesList} from "./currencies.js";

export const constants = {

    options: {
        yesno: [{value: "yes", label: "Yes"}, {value: "no", label: "No"}],
        roles: [{value: "user", label: "User"}, {value: "admin", label: "Admin"}],
        countries: countriesList,
        languages: languagesList,
        currencies: currenciesList,
    },
}
