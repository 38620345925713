<template>

  <div v-if="!isEmpty(application)">
    <q-tabs
        v-model="tab"
        class="bg-blue-1"
        active-color="primary"
        indicator-color="primary"
        align="right"
    >
      <q-tab name="translations" :label="$t('application.translations')" />
      <q-tab name="settings" :label="$t('application.settings')" />
    </q-tabs>

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="translations">
        <application-translations :application="application"/>
      </q-tab-panel>
      <q-tab-panel name="settings">
        <application-settings :application="application"/>
      </q-tab-panel>
    </q-tab-panels>
  </div>

</template>

<script>

import {Application} from "@/../../db/models/Application";
import ApplicationSettings from "@/pages/workspace/applications/ApplicationSettings";
import ApplicationTranslations from "@/pages/workspace/applications/ApplicationTranslations";
export default {
  name: "ApplicationDetails",
  components: { ApplicationSettings, ApplicationTranslations },
  data: () => ({
    tab: "translations",
  }),
  computed: {
    application() {
      return this.wait("application", Application.find(this.$route.params.application_id), {})
    }
  }
}

</script>
