<template>
  <q-form ref="applicationForm" @submit="onSaveApplication" class="q-gutter-md">
    <q-input
      filled
      v-model="mutate(editApplication).name"
      :label="`${$t('application.name')} *`"
      lazy-rules
      :rules="[ val => val && val.length > 0 || $t('validation.required')]"
    />
    <q-input
      filled
      autogrow
      v-model="mutate(editApplication).description"
      :label="$t('application.description')"
    />
    <q-input
      filled
      v-model="mutate(editApplication).secret_key"
      :label="`${$t('application.secretKey')} *`"
      lazy-rules
      :rules="[ val => val && val.length > 0 || $t('validation.required')]"
    />

    <q-select 
      lazy-rules
      emit-value
      map-options
      multiple
      use-chips
      :label="`${$t('application.languages')} *`"
      v-model="mutate(editApplication).languages"
      :options="globals.options.languages"
      :rules="[ val => val && val.length > 0 || $t('validation.required')]"
    />

    <q-select 
      lazy-rules
      emit-value
      map-options
      clearable
      :label="`${$t('application.defaultLanguage')} *`"
      v-model="mutate(editApplication).default_language"
      :options="defaultLanguageOptions"
      :rules="[ val => val && val.length > 0 || $t('validation.required')]"
    />

    <q-select
      multiple
      emit-value
      use-chips
      :label="$t('application.users')"
      v-model="mutate(editApplication).users"
      :options="users"
      option-value="email"
      option-label="email"
    />

    <div>
      <q-btn label="Save" type="submit" color="primary"/>
    </div>
  </q-form>
</template>

<script>

import {Application} from "@/../../db/models/Application";
import {User} from "@/../../db/models/User";

export default {
  name: "ApplicationFields",
  props: {
    editApplication: {
      type: Object,
      required: true
    }
  },
  emits: ['saved'],
  /**
   * On page created
  */
  async created() {
    // Fetch all users
    await User.remote().subscribe('users')
  },
  computed: {
    // Return all Users from the store
    users() {
      return this.wait('users', User.query().get(), [])
    },
    defaultLanguageOptions() {
      if(this.editApplication.languages?.length === 0) {
        return [];
      }
      return this.globals.options.languages.filter((lang) => this.editApplication.languages?.includes(lang.value))
    }
  },
  methods: {
    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveApplication() {

      // Update
      await Application.remote().save(this.editApplication)

      // Emit event saved
      this.$emit('saved')
    }
  }
}

</script>
