<template>

  <q-card>
    <q-card-section>
      <div class="text-h6">{{$t(titleKey)}}</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="userForm" class="q-gutter-md" @submit="onSaveUser">
        <template v-for="(field) of inputFields" :key="field.name">
          <q-input
            filled
            v-model="mutate(editUser)[field.name]"
            :label="field.label"
            :type="field.type"
            lazy-rules
            :rules="[ val => val && val.length > 0 || $t('validation.required')]"
          />
        </template>
        
        <q-select
          filled
          emit-value
          map-options
          v-model="mutate(editUser).roles"
          :label="$t('user.roles')"
          multiple
          :options="globals.options.roles"
          use-chips
          :rules="[ val => val && val.length > 0 || $t('validation.required')]"
        />
        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {User} from "@/../../db/models/User";

export default {
  name: "UserForm",
  props: {
    titleKey: {
      type: String,
      default: 'user.edit'
    },
    editUser: {
      type: Object,
      required: true
    }
  },
  emits: ['saved'],
  data: vm => ({
    inputFields: [
      {
        name: 'first_name',
        label: vm.$t('user.firstName'),
        type: 'text',
      },
      {
        name: 'last_name',
        label: vm.$t('user.lastName'),
        type: 'text',
      },
      {
        name: 'email',
        label: vm.$t('user.email'),
        type: 'email',
      },
      {
        name: 'password',
        label: vm.$t('user.password'),
      },
    ]
  }),
  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveUser() {

      // Update
      await User.remote().save(this.editUser)

      // Hide dialog
      this.$emit('saved')
    }
  }
}

</script>
