<template>
  <q-card>
    <q-card-section>
      <div class="text-h6">{{$t('actions.upload')}}</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="form" class="q-gutter-md" @submit="onSaveApplication">
        <q-file
          ref="file"
          v-model="file"
          label="Pick .xml,.json or .strings"
          accept=".xml,.json,.strings"
          clearable
          filled
          counter
          color="gray"
          :error="!!errorFileMessage"
        >
          <template v-slot:error>
            {{ errorFileMessage }}
          </template>
          <template v-slot:prepend>
            <q-icon name="cloud_upload" />
          </template>
        </q-file>
        <q-select
          emit-value
          clearable
          :label="`${$t('application.language')} *`"
          v-model="selectedLanguage"
          :options="languageOptions"
          lazy-rules
          :rules="[ val => val && val.length > 0 || $t('validation.required')]"
        />
        <q-checkbox v-if="application?.default_language !== selectedLanguage" class="q-mx-md" v-model="isApplyMaxLength" label="Apply max line length" />
      </q-form>
    </q-card-section>

    <q-card-actions class="q-px-md q-py-md justify-end">
      <q-btn class="q-mx-md" label="Save" color="primary" @click="onSave"/>
      <q-btn class="q-mx-md" label="Cancel" color="secondary" @click="onCancelClick" />
    </q-card-actions>

  </q-card>

</template>

<script>
import {Translation} from "@/../../db/models/Translation";
import xml2js from 'xml2js';

export default {
  name: "ApplicationTranslationUpload",
  emits: ['close'],
  props: ['application', 'languageOptions'],
  data: () => ({
    selectedLanguage: "",
    file: null,
    errorFileMessage: "",
    isApplyMaxLength: false,
  }),
  methods: {
    onSave() {
      this.$refs.form.submit();
    },
    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveApplication() {
      this.validateFile();
      if(this.errorFileMessage) return;

      const reader = new FileReader();

      reader.onload = async() => {
        const fileData = reader.result;
        console.log("fileData:", fileData);

        let fileType = this.file.type;
        let parsedFile = null;

        if(fileType === 'text/xml') {
          parsedFile = await this.parseXml(fileData);
        } 
        else if (this.file.type === 'application/json') {
          const paresdJson = JSON.parse(fileData);
          parsedFile = this.flatten(paresdJson);
        } else if (!fileType) {
          fileType = "strings";
          parsedFile = await this.parseStrings(fileData);
        }

        if(parsedFile) {
          console.log("parsedFile", parsedFile)
          this.saveParsedFile(parsedFile, fileType);
        }
      };
      reader.readAsText(this.file);
    },
    onCancelClick() {
      this.$emit('close')
    },
    parseXml(xmlData) {
      return xml2js.parseStringPromise(xmlData)
      .then((result) => {
        return result.resources.string.map(str => ({
          variable: str.$.name,
          value: str._,
          translatable: str.$.translatable === 'false'? 0 : 1
        }));
      }).catch((err) => {
        this.errorFileMessage = err;
        console.error(err);
        return [];
      })
    },
    parseStrings(stringsData) {
      const regex = /"([^"]+)"\s*=\s*"([^"]+)";/g;

      return [...stringsData.matchAll(regex)].map(([, variable, value]) => ({ variable, value, translatable: 1 }));
    },

    flatten(obj, prefix = "") {
      return Object.keys(obj).reduce((acc, key) => {
        const propName = `${prefix}${key}`;
        if (typeof obj[key] === "object") {
          return acc.concat(this.flatten(obj[key], `${propName}.`));
        } else {
          return acc.concat({ variable: propName, value: obj[key], translatable: 1 });
        }
      }, []);
    },

    async saveParsedFile(parsedXml, fileType) {
      this.$q.loading.show({ message: 'Saving...' });
      try {
        for (const str of parsedXml) {
          await this.saveString(str, fileType);
        }
      } catch (error) {
        this.$q.loading.hide();
        this.triggerNotify(error.message || error, 'negative');
        console.error(error);
      }
      this.$q.loading.hide();
      // Emit event close
      this.$emit('close', {language: this.selectedLanguage})
    },

    async saveString(str, fileType) {
      const otherLanguages = this.application.languages.filter(language => language !== this.selectedLanguage);
      const data = await Translation.query()
        .where({application_id: this.application.id, language: this.selectedLanguage, variable: str.variable }).first();

      if(!data) {
        await this.saveNewString(str, fileType, otherLanguages);
      } else {
        await this.updateExistingString(str, fileType, data, otherLanguages);
      } 
    },

    async saveNewString({variable, value, translatable}, fileType, otherLanguages) {
      let newMaxLength = 0;

      if(this.isApplyMaxLength && (this.selectedLanguage !== this.application.default_language)) {
        const defaultLangData = await Translation.query()
          .where({application_id: this.application.id, language: this.application.default_language, variable }).first();
        newMaxLength = value.length > defaultLangData?.max_length ? value.length : defaultLangData?.max_length || value.length;

        await this.updateOtherTranslations(otherLanguages, variable, fileType, newMaxLength);
      }

      await Translation.remote().save({
        application_id: this.application.id,
        language: this.selectedLanguage,
        max_length: newMaxLength || value?.length || 0,
        variable,
        value,
        translatable,
      })
    },

    async updateExistingString(str, fileType, data, otherLanguages) {
      const { variable, value, translatable } = str;
      if(this.selectedLanguage === this.application.default_language) {
        await this.updateDefaultLanguage(str, data);

        const newMaxLength = value?.length > data?.max_length ? value.length : data?.max_length || value?.length;

        for (const language of otherLanguages) {
          const otherData = await Translation.query()
          .where({application_id: this.application.id, language, variable }).first();

          if(!otherData?.id) continue;

          if(translatable !== data.translatable) {
            await Translation.remote().save({id: otherData.id, translatable })
          }
          
          if(data.value !== value) {
            await Translation.remote().save({id: otherData.id, value: "", max_length: newMaxLength})
          } else if (otherData.max_length !== newMaxLength) {
            await Translation.remote().save({id: otherData.id, max_length: newMaxLength })
          }
        }
      } else {
        await this.updateNonDefaultLanguage(str, fileType, data);

        const defaultLangData = await Translation.query()
          .where({application_id: this.application.id, language: this.application.default_language, variable }).first();
        const newMaxLength = this.isApplyMaxLength && (value.length > defaultLangData.max_length) ? value.length : defaultLangData?.max_length || value.length;

        await this.updateOtherTranslations(otherLanguages, variable, fileType, newMaxLength);
      }
    },

    async updateDefaultLanguage({value, translatable}, data) {
      const newMaxLength = value?.length > data?.max_length ? value.length : data?.max_length || value?.length;
      await this.updateTranslation(data.id, value, translatable, newMaxLength);
    },

    async updateNonDefaultLanguage({variable, value, translatable}, fileType, data) {
      const defaultLangData = await Translation.query()
        .where({application_id: this.application.id, language: this.application.default_language, variable: variable }).first();
      const newMaxLength = (this.isApplyMaxLength && (value.length > defaultLangData.max_length)) ? value.length : defaultLangData.max_length || value.length;

      await this.updateTranslation(data.id, value, translatable, newMaxLength);
    },

    async updateOtherTranslations(otherLanguages, variable, fileType, newMaxLength) {
      const promises = otherLanguages.map(async (language) => {
        const otherData = await Translation.query()
          .where({application_id: this.application.id, language, variable }).first();

        if(!otherData?.id) return;

        await Translation.remote().save({id: otherData.id, max_length: newMaxLength })
      });

      await Promise.all(promises);
    },

    async updateTranslation(id, value, translatable, max_length) {
      await Translation.remote().save({
        id,
        value,
        translatable,
        max_length,
      });
    },

    validateFile () {
      if (!this.file) {
        this.errorFileMessage = 'File is required'
      } else if (!['text/xml', 'application/json', ""].includes(this.file.type)) {
        this.file = null
        this.errorFileMessage = 'File must be an XML,JSON or Strings file'
      } else {
        this.errorFileMessage = ''
      }
    },
    triggerNotify(message, type="positive") {
      this.$q.notify({
        type,
        message,
      })
    },
  }
}

</script>
