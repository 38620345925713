import DefaultTemplate from "./templates/DefaultTemplate";
import LoginPage from "./pages/auth/LoginPage";
import WorkspaceTemplate from "./pages/workspace/templates/WorkspaceTemplate";
import ApplicationsList from "./pages/workspace/applications/ApplicationsList";
import ApplicationDetails from "@/pages/workspace/applications/ApplicationDetails";
import UsersList from "./pages/workspace/users/UsersList";
import HomePage from "./pages/HomePage";
import SignupPage from "./pages/auth/SignupPage";

// eslint-disable-next-line no-unused-vars
export const routes = [
    {
        path: '/',
        component: DefaultTemplate,
        children: [
            {
                name: 'home',
                path: '/',
                component: HomePage
            },
            {
                name: 'login',
                path: '/login',
                component: LoginPage
            },
            {
                meta: {
                    auth: {
                        roles: ["user"]
                    }
                },
                name: 'signup',
                path: '/signup',
                component: SignupPage
            },
        ]
    },

    // Workspace
    {
        path: '/workspace',
        component: WorkspaceTemplate,
        meta: {
            auth: {
                roles: ["user", "admin"],
                redirect: "login"
            }
        },
        children: [
            {
                name: 'workspace',
                path: '/workspace',
                component: HomePage
            },

            {
                name: 'workspace-applications',
                path: '/workspace/applications-list',
                component:  ApplicationsList,
                meta: { auth: { roles: ["admin"] } },
            },
            {
                name: 'application',
                path: '/workspace/:application_id',
                component: ApplicationDetails,
                meta: { auth: { roles: ["admin"] } },
            },
            {
                name: 'users',
                path: '/workspace/admin/users',
                component:  UsersList,
                meta: { auth: { roles: ["admin"] } },
            },
        ]
    }
]
