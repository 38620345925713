import {AbOrm} from "ab-orm/src/AbOrm.js";

/**
 * User model
 */
export class User extends AbOrm {
    static entity = 'users'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        first_name: "string",
        last_name: "string",
        email: "string",
        password: "string",
        token: "string",
        roles: "json",
        product_id: "int"
    }


    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        return this.app().auth().hasRole("admin") ? row : false
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'users': {
                subscribe: () => 1,
                init: async () => this.getList(),
            }
        }
    }

    /**
     * Get list
     */
    getList() {
        return this.query().get()
    }
}
